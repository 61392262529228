import React, {useEffect, useState} from "react"
import {Container, Row, Col} from "react-bootstrap";
import {GetPage} from "../../services/pages.service";
import ReactHtmlParser from "react-html-parser";
import DriverPublicHeader from "../app-header/driver-public-header";
import AppHeader from "../app-header/public-header";
import FooterNew from "../app-footer/FooterNew";
import {GetCurrentUser} from "../../services/auth/auth.service";
import {constants} from "../../utils/constants";

const PageTemplate = ({slug}) => {
    const [data, setData] = useState([])
    const user = GetCurrentUser();
    const [isDriver, setIsDriver] = useState(false);
    useEffect(() => {
        setIsDriver(user?.roles[0].id === constants.ROLES.ROLE_DRIVER)
        getPage()
    }, [])

    async function getPage() {
        await GetPage({slug}).then((result) => {
            if (result.status && result.data) {
                setData(result.data)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    return (
        <>
            {isDriver ? <DriverPublicHeader/> : <AppHeader/>}
            <div className="faq-page">
                <div className="faq-container">
                    <div className="banner-img"></div>
                    <Container>
                        <Row className="justify-content-center py-5">

                            <Col xs={12} md={12} xl={9}>
                                <Row>
                                    <Col xs={12}>
                                        <div className="w-100 pb-4 py-xl-5 text-center">
                                            {/*<h1>Return & Cancellation</h1>*/}
                                            <h1>{data?.title || ""}</h1>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        <div className="ps-xl-5">
                                            <p className="mt-3">
                                                {ReactHtmlParser(data?.content) || "No Data Found"}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <FooterNew/>
        </>
    )
}

export default PageTemplate;

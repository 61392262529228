import {constants, apiUrl} from "../utils/constants";
import {GET} from "./api.service.wrapper";


export const GetCategories = async () => {
    let args = {
        "order-column" : "name",
        "order": "asc",
        "is-paginate":0
    }
    return await GET(apiUrl.categories, args)
}
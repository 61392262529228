import { USER_ACTIONS } from "../actions";

export default function user(state = {}, action) {
  switch (action.type) {
    case USER_ACTIONS.USER_LOGIN: {
      let user = action.payload.data;
      let newState = {
        ...state,
        tempEmail: action.payload.email,
        loggedIn: true,
        loginFailed: false,
        details: user,
        user_id: user.id,
        email: user.email,
        role: user.__meta__.rolesCsv,
        access: user.access_token,
        loginSuccessMsg: action.payload.message,
        loginRequest: false,
        loginSuccess: true,
      };
      return newState;
    }
    case USER_ACTIONS.USER_LOGIN_FAILED: {
      return {
        ...state,
        loggedIn: false,
        error: action.error,
        loginFailed: true,
        loginRequest: false,
        loginSuccess: false,
      };
    }
    case USER_ACTIONS.USER_LOGIN_REQUEST: {
      return {
        ...state,
        loginRequest: true,
        loginFailed: false,
        loginSuccess: false,
      };
    }

    case USER_ACTIONS.REFRESH_TOKEN: {
      let user = action.payload.data;
      return {
        ...state,
        access: user.access_token,
      };
    }
    default:
      return state;
  }
}

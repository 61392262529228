import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap"
import FooterNew from "../../components/app-footer/FooterNew"
import NewDriverHeader from "../../components/app-header/NewDriverHeader"
import DriverProfileModal from "../../components/profile-modal/DriverProfileModal";
import {IoMdSettings} from "react-icons/io";
import {DriverSidebar} from "../../App";
import {BsChevronLeft} from "react-icons/bs"
import {useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import {GetDriverPaymentHistory, GetProfile} from "../../services/general.service";
import Moment from "moment";

const PaymentHistory = () => {
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);
    const [data, setData] = useState([]);
    const navigate = useNavigate()

    const handleDriverSidebar = () => {
        setShowDriverSidebar(true)
        document.body.classList.add("handleProfilemodal");
    }

    const rows = [
        {
            id: "1",
            date: "22/12/21",
            description: "Payment received against order ID 221220",
            amount: "$ 320",
            ref_id: "321321",
        },
        {
            id: "2",
            date: "22/12/21",
            description: "Payment received against order ID 221220",
            amount: "$ 320",
            ref_id: "321321",
        },
        {
            id: "3",
            date: "22/12/21",
            description: "Payment received against order ID 221220",
            amount: "$ 320",
            ref_id: "321321",
        },
        {
            id: "4",
            date: "22/12/21",
            description: "Payment received against order ID 221220",
            amount: "$ 320",
            ref_id: "321321",
        },
        {
            id: "5",
            date: "22/12/21",
            description: "Payment received against order ID 221220",
            amount: "$ 320",
            ref_id: "321321",
        },
    ]

    const columns = [
        {
            field: "created_at",
            headerName: "Date",
            minWidth: 150,
            flex: 1,
            align: "left",
            headerAlign: "left",
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        {Moment(params.value).format("YYYY-MM-DD")}
                    </div>
                );
            }
        },
        // {
        //     field: "description",
        //     headerName: "Description",
        //     minWidth: 150,
        //     flex: 1,
        //     align: "center",
        //     headerAlign: "center",
        //     filterable: false,
        // },
        {
            field: "amount",
            headerName: "Amount",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        ${params.value?.toLocaleString("en-US")}
                    </div>
                );
            }
        },
        // {
        //     field: "ref_id",
        //     headerName: "Ref.ID",
        //     minWidth: 150,
        //     flex: 1,
        //     align: "center",
        //     headerAlign: "center",
        //     filterable: false,
        // },
    ];

    async function getDriverPaymentHistory() {
        await GetDriverPaymentHistory({is_mine:1}).then((result) => {

            if (result.status && result.data) {
                 setData(result.data.data)
            }
        }).catch((error) => {
        })
    }

    useEffect(() => {
        getDriverPaymentHistory()
    }, [])

    return (
        <>
            <NewDriverHeader/>
            <div className="summary-trips">
                <div className="d-flex">
                    <div className="left">
                        {
                            showDriverSidebar === true &&
                            <DriverProfileModal/>
                        }
                    </div>

                    <div className="right py-5">
                        <Container>
                            <Row>
                                <Col className="d-block d-md-none" xs={12}>
                                    <div className="text-end">
                                        <IoMdSettings onClick={() => handleDriverSidebar(true)}
                                                      className="text-white icon-setting"/>
                                        {
                                            showDriverSidebar == false &&
                                            <hr/>
                                        }
                                    </div>
                                </Col>

                                <Col xs={12}>
                                    <h1><BsChevronLeft onClick={() => navigate("/withdrawl")}
                                                       className="chiv-icon-left"/> Payment History</h1>
                                </Col>

                                <Col xs={12} className="mt-4">
                                    <div className="ProductProductTable PaymentHistoryTable NewOrderTable mt-4"
                                         style={{height: 530, width: "100%"}}>
                                        <DataGrid
                                            disableColumnMenu
                                            disableColumnFilter
                                            disableColumnSelector
                                            className="text-white data-table"
                                            rows={data}
                                            columns={columns}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <FooterNew/>
        </>

    )
}

export default PaymentHistory
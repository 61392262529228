import React, {useContext, useState, useEffect, useRef} from "react";
import {Modal, Button, Row, Col, Form, Container,} from "react-bootstrap";
import DriverProfileModal from "../../../components/profile-modal/DriverProfileModal";
import {IoMdSettings} from "react-icons/io";
import {DriverSidebar} from "../../../App";
import FooterNew from "../../../components/app-footer/FooterNew";
import NewDriverHeader from "../../../components/app-header/NewDriverHeader";
import {DataGrid} from "@mui/x-data-grid";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {GetDriverOrders} from "../../../services/orders.service";
import {constants, orderStatus} from "../../../utils/constants";
import moment from "moment";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"

const pageTitle = {
    [orderStatus.ORDERED]: "New Orders",
    [orderStatus.ACCEPTED]: "Current Orders",
    [orderStatus.DISPATCHED]: "Past Orders",
    [orderStatus.DELIVERED]: "Completed Orders"
}

const NewOrderPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const calendarRef = useRef()
    const [selectedDate, setSelectedDate] = useState({});
    const [currentPageTitle, setCurrentPageTitle] = useState(null);
    const [page, setPage] = useState(1);
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);
    const [rows, setRows] = useState([]);
    const [status, setStatus] = useState(orderStatus.ORDERED)


    const handleDriverSidebar = () => {

        setShowDriverSidebar(true)
        document.body.classList.add("handleProfilemodal");
    }

    async function getDriverOrders() {
        let status = orderStatus.ORDERED
        switch (location.pathname) {
            case "/driver/current-orders":
                status = orderStatus.ACCEPTED
                break;
            case "/driver/past-orders":
                status = orderStatus.DISPATCHED
                break;
            case "/driver/completed-orders":
                status = orderStatus.DELIVERED
                break;
        }
        await GetDriverOrders({
            status: status,
            page: page,
            start_date: selectedDate.start,
            end_date: selectedDate.end
        }).then((result) => {
            if (result.status && result.data) {
                let responseData = []
                result.data.map((v) => {
                    let start_date = moment(v.created_at).format("YYYY-MM-DD")
                    let end_date = moment(v.created_at).format("YYYY-MM-DDThh:mm:ss")
                    responseData.push(
                        {
                            id: v.id,
                            order_id: v.order_number,
                            customer_name: v.billing_first_name + " " + v.billing_last_name,
                            order_created: start_date,
                            total: v.total_amount,
                            phone_number: v.billing_phone,
                            sub_total: v.sub_total_amount,
                            textColor: "#000000",
                            backgroundColor: "#c32126",
                        }
                    )
                })
                setRows(responseData)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }


    const columns = [
        {
            field: "order_id",
            headerName: "Order ID",
            minWidth: 150,
            flex: 1,
            align: "left",
            headerAlign: "left",
            filterable: false,
        },
        {
            field: "customer_name",
            headerName: "Customer Name",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        },
        {
            field: "phone_number",
            headerName: "Phone Number",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        },
        {
            field: "order_created",
            headerName: "Order Created",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        },
        {
            field: "sub_total",
            headerName: "Sub Total ($)",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        },
        {
            field: "total",
            headerName: "Total ($)",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        }
    ];

    useEffect(() => {
        let page_title = "New Orders"
        switch (location.pathname) {
            case "/driver/current-orders":
                page_title = "Current Orders"
                break;
            case "/driver/past-orders":
                page_title = "Dispatched Orders"
                break;
            case "/driver/completed-orders":
                page_title = "Completed Orders"
                break;
        }
        setCurrentPageTitle(page_title)
        getDriverOrders()
    }, [page, selectedDate, location.pathname])
    console.log("path:", location.pathname)
    return (
        <>
            <NewDriverHeader/>
            <div className="summary-trips">
                <div className="d-flex">
                    <div className="left">
                        {
                            showDriverSidebar === true &&
                            <DriverProfileModal/>
                        }
                    </div>

                    <div className="right py-5">
                        <Container>
                            <Row>
                                <Col className="d-block d-md-none" xs={12}>
                                    <div className="text-end">
                                        <IoMdSettings onClick={() => handleDriverSidebar(true)}
                                                      className="text-white icon-setting"/>
                                        {
                                            showDriverSidebar == false &&
                                            <hr/>
                                        }
                                    </div>
                                </Col>

                                <Col xs={12} md={12} lg={6} className="">
                                    <h1>{currentPageTitle}</h1>
                                </Col>

                                <Col xs={12} md={12} lg={6} className="my-auto">
                                    <div className="custom mt-2 mt-lg-0 d-flex justify-content-lg-end">
                                        {/* <div className="d-flex align-items-center me-3">
                                            <div className="circle-purple me-3"></div>
                                            <span>On the way</span>
                                        </div>

                                        <div className="d-flex align-items-center me-3">
                                            <div className="circle-yellow me-3"></div>
                                            <span>Current Orders</span>
                                        </div> */}

                                        <div className="d-flex align-items-center me-1">
                                            <div className="circle-red me-3"></div>
                                            <span>{currentPageTitle}</span>
                                        </div>
                                    </div>
                                </Col>

                                {/* <Col xs={12} md={6} className="mt-4">
                                    <div className="sort-container">
                                        <Button className="active">Month</Button>
                                        <Button>Week</Button>
                                        <Button>Day</Button>
                                    </div>
                                </Col>

                                <Col xs={12} md={6} className="mt-4">
                                    <div className="d-flex align-items-center justify-content-start justify-content-md-end text-white">
                                        <div className="chev-icon-div">
                                            <FiChevronLeft className="text-grey icon" />
                                        </div>

                                        <div className="mx-3">
                                            <h5 className="m-0">January 2022</h5>
                                        </div>

                                        <div className="chev-icon-div">
                                            <FiChevronRight  className="text-grey icon" />
                                        </div>
                                    </div>
                                </Col> */}

                                <div className="newCalendar mt-3">
                                    <FullCalendar
                                        ref={calendarRef}
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        headerToolbar={{
                                            left: "prev,next",
                                            center: "title",
                                            right: "dayGridMonth,timeGridWeek,timeGridDay"
                                        }}
                                        initialView="timeGridWeek"
                                        displayEventTime={true}
                                        datesSet={(date) => {
                                            setSelectedDate({
                                                start: date.startStr.split("T")[0],
                                                end: date.endStr.split("T")[0]
                                            })
                                        }}
                                    />
                                </div>

                                <Col xs={12}>
                                    <div className="ProductProductTable  NewOrderTable mt-4"
                                         style={{height: 530, width: "100%"}}>
                                        <DataGrid
                                            disableColumnMenu
                                            disableColumnFilter
                                            disableColumnSelector
                                            className="text-white data-table"
                                            rows={rows}
                                            columns={columns}
                                            onRowClick={(row) => {
                                                navigate("/driver/order-details", {state: {id: row.id, status: status}})
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <FooterNew/>
        </>
    )
}

export default NewOrderPage

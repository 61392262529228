import { BASE_URL, ENDPOINTS } from "../../constants/app-constants";
import { curl_get, requestSuccessAction } from "./http";

const types = {
  GET_TIMEZONES: "GET_TIMEZONES",
  GET_TIMEZONES_REQUEST: "GET_TIMEZONES_REQUEST",
  GET_TIMEZONES_FAILED: "GET_TIMEZONES_FAILED",
};

export default types;

export function getTimezones() {
  return (dispatch) => {
    dispatch({ type: types.GET_TIMEZONES_REQUEST });
    curl_get(
      dispatch,
      BASE_URL + ENDPOINTS.GET_TIMEZONES,
      {},
      types.GET_TIMEZONES_FAILED,
      (data) => {
        dispatch(requestSuccessAction({ data }, types.GET_TIMEZONES));
      }
    );
  };
}

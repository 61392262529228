import { applyMiddleware, createStore, compose } from "redux";

import createRootReducer from "./reducers";
import { routerMiddleware } from "connected-react-router";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { install } from "redux-loop";
import { createBrowserHistory } from "history";
import { saveState, loadState } from "../constants/storage";

export const history = createBrowserHistory();

const enhancer = compose(
  install(),
  applyMiddleware(routerMiddleware(history), thunk, logger)
);
const initialState = loadState();
const store = createStore(createRootReducer(history), initialState, enhancer);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;

import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import user from "./user";
import general from "./general";

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    general,
  });

export default reducer;

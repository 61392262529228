import { GENERAL_ACTIONS } from "../actions";

export default function general(state = {}, action) {
  switch (action.type) {
    //get timezones
    case GENERAL_ACTIONS.GET_TIMEZONES_REQUEST: {
      return {
        ...state,
        timeZoneRequest: true,
        timeZoneFailed: false,
        timeZoneSuccess: false,
      };
    }
    case GENERAL_ACTIONS.GET_TIMEZONES: {
      return {
        ...state,
        timeZoneRequest: false,
        timeZoneFailed: false,
        timezones: action.payload.data.data,
        timeZoneSuccess: true,
      };
    }
    case GENERAL_ACTIONS.GET_TIMEZONES_FAILED: {
      return {
        ...state,
        timeZoneRequest: false,
        error: action.error,
        timeZoneFailed: true,
        timeZoneSuccess: false,
      };
    }
    default:
      return state;
  }
}

import general from "./general";
import user from "./user";

export const GENERAL_ACTIONS = general;
export const USER_ACTIONS = user;

//EXPORT ALL ACTIONS
export default {
  GENERAL_ACTIONS: general,
  USER_ACTIONS: user,
};

import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row,} from "react-bootstrap";
import DriverProfileModal from "../../components/profile-modal/DriverProfileModal";
import {IoMdSettings} from "react-icons/io";
import {DriverSidebar} from "../../App";
import FooterNew from "../../components/app-footer/FooterNew";
import NewDriverHeader from "../../components/app-header/NewDriverHeader";
import {NavLink} from "react-router-dom";
import {GetProfile, GetTotalWithDrawl} from "../../services/general.service";
import {GetProductTypes} from "../../services/product_types.service";
import {GetCurrentUser} from "../../services/auth/auth.service";

const WithDrawlPage = () => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(0);

    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);
    const handleDriverSidebar = () => {
        setShowDriverSidebar(true)
        document.body.classList.add("handleProfilemodal");
    }

    async function getProfile() {
        await GetProfile().then((result) => {
            if (result.status && result.data) {
                setData(result.data.user)

            }
        }).catch((error) => {
        })
    }

    async function getTotalWithDrawl() {
        await GetTotalWithDrawl().then((result) => {
            if (result.status && result.data) {
                setTotal(result.data.total)

            }
        }).catch((error) => {
        })
    }

    useEffect(() => {
        getProfile()
        getTotalWithDrawl()
    }, [])

    return (
        <>
            <NewDriverHeader/>
            <div className="summary-trips">
                <div className="d-flex">
                    <div className="left">
                        {
                            showDriverSidebar === true &&
                            <DriverProfileModal/>
                        }
                    </div>

                    <div className="right py-5">
                        <Container>
                            <Row>
                                <Col className="d-block d-md-none" xs={12}>
                                    <div className="text-end">
                                        <IoMdSettings onClick={() => handleDriverSidebar(true)}
                                                      className="text-white icon-setting"/>
                                        {
                                            showDriverSidebar == false &&
                                            <hr/>
                                        }
                                    </div>
                                </Col>

                                <Col xs={12} md={12} className="mb-4">
                                    <h1>Withdrawl</h1>
                                </Col>

                                <Col xs={12} md={12} lg={8} xl={6}>
                                    <div className="withdrawl-page">
                                        <div className="box">
                                            <h6 className="m-0 p-3 border-bottom">Balance</h6>
                                            <p className="m-0 p-3">Available balance
                                                is <b>${data?.driver_detail?.wallet_amount?.toLocaleString("en-US") || 0}</b></p>
                                        </div>

                                        <div className="box mt-4">
                                            <h6 className="m-0 p-3 border-bottom">Payment Details</h6>
                                            <div className="p-3 pb-2 border-bottom">
                                                <p className="m-0">Amount available for withdrawl</p>
                                                <p><b>${data?.driver_detail?.wallet_amount?.toLocaleString("en-US") || 0}</b></p>
                                            </div>

                                            <div
                                                className="p-3 pb-2 border-bottom d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="me-3">
                                                    <p className="m-0">Withdrawl amount so far</p>
                                                    <p><b>${total?.toLocaleString("en-US") || 0}</b></p>
                                                </div>
                                                <div>
                                                    <NavLink to="/payment-history" className="py-2 px-3 view-payment">View
                                                        Payments</NavLink>
                                                </div>
                                            </div>

                                            <div className="p-3 pb-2">
                                                <p className="m-0">Next withdrawl date</p>
                                                <p><b>Sep 26, 2022</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <FooterNew/>
        </>
    )
}

export default WithDrawlPage

import React, {createContext, useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import Navigation from "./Navigation";
import Assets from "./constants/images";
import {GetProductTypes} from "./services/product_types.service";
import {GetCategories} from "./services/categories.service";
import {GetBrands} from "./services/brands.service";
import {BrandsContext, CategoriesContext, IsDriverApprovedContext, ProductTypesContext} from "./hooks/GlobalContext"
import {GetCurrentUser} from "./services/auth/auth.service";
// import {loadStripe} from "@stripe/stripe-js";
// import {Elements,} from "@stripe/react-stripe-js";
import {constants} from "./utils/constants";
import {GoogleOAuthProvider} from "@react-oauth/google";

const LocationModal = createContext();
const CustomItemModal = createContext();
const Cart = createContext();
const ProfileModals = createContext();
const FilterSidebar = createContext();
const DriverSidebar = createContext();
const AllProducts = createContext();
const StickyNavbar = createContext();

// just for test
const IsCustomerLogin = createContext();

const App = () => {
    const fetchCartData = () => {
        const data = window.localStorage.getItem("cart");
        if (data) {
            return JSON.parse(window.localStorage.getItem("cart"));
        } else {
            return []
        }
    }

    const [stickyNavbar, setStickyNavbar] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [showCustomItemModal, setShowCustomItemModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showFilterSidebar, setShowFilterSidebar] = useState(false);
    const [showDriverSidebar, setShowDriverSidebar] = useState(true);
    const [cart, setCart] = useState(fetchCartData());
    const [productTypeData, setProductTypeData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [brandsData, setBrandsData] = useState([]);
    const [isCustomerLogin, setIsCustomerLogin] = useState(null);
    const [isDriverVerified, setIsDriverVerified] = useState(0);
    // const [stripePromise, setStripePromise] = useState(() => loadStripe(constants.STRIPEPK.key))
    const [product, setProduct] = useState([
        {
            id: "0",
            image: Assets.ProductImage1,
            title: "DeWalt DCK266P2T 18V XR Brushless Combi & Impact Driver",
            rating: 5,
            buy: "312",
            price: "134.90",
            weight: "20"
        },
        {
            id: "1",
            image: Assets.ProductImage2,
            title: "Grohe Uniset 3'' Distance Pipe For Rapid SL Frame Black",
            rating: 5,
            buy: "312",
            price: "134.90",
            weight: "30"
        },
        {
            id: "2",
            image: Assets.ProductImage3,
            title: "DeWalt DCG412N-XJ 18V XR Grinder Bare Unit",
            rating: 5,
            buy: "312",
            price: "134.90",
            weight: "40"
        },
        {
            id: "3",
            image: Assets.ProductImage4,
            title: "Paslode IM65 F16 Brad Nailer 1 X Li-Ion Battery & Accessories",
            rating: 5,
            buy: "312",
            price: "134.90",
            weight: "50"
        },
        {
            id: "4",
            image: Assets.ProductImage5,
            title: "DeWalt DCK266P2T 18V XR Brushless Combi & Impact Driver",
            rating: 5,
            buy: "312",
            price: "134.90",
            weight: "60"
        },
        {
            id: "5",
            image: Assets.ProductImage6,
            title: "Grohe Uniset 3'' Distance Pipe For Rapid SL Frame Black",
            rating: 5,
            buy: "312",
            price: "134.90",
            weight: "70"
        },
        {
            id: "6",
            image: Assets.ProductImage7,
            title: "DeWalt DCG412N-XJ 18V XR Grinder Bare Unit",
            rating: 5,
            buy: "312",
            price: "134.90",
            weight: "80"
        },
        {
            id: "7",
            image: Assets.ProductImage8,
            title: "Paslode IM65 F16 Brad Nailer 1 X Li-Ion Battery & Accessories",
            rating: 5,
            buy: "312",
            price: "134.90",
            weight: "90"
        },
    ]);

    useEffect(() => {
        fetchProductType();
        fetchBrands();
        fetchCategories();
        const _user = GetCurrentUser()
        if (_user) {
            setIsCustomerLogin(_user)
        }
    }, [])

    async function fetchProductType() {
        await GetProductTypes().then((result) => {
            if (result.status && result.data) {
                setProductTypeData(result.data)
            }
        }).catch((error) => {
        })
    }

    async function fetchBrands() {
        await GetBrands().then((result) => {
            if (result.status && result.data) {
                setBrandsData(result.data)
            }
        }).catch((error) => {

        })
    }

    async function fetchCategories() {
        await GetCategories().then((result) => {
            if (result.status && result.data) {
                setCategoriesData(result.data)
            }
        }).catch((error) => {

        })
    }

    return (
        <>
            {/* For routing enable */}
            <GoogleOAuthProvider clientId="1092161160477-uh4fub3pojkfd48ah0btb1l2avn29hkr.apps.googleusercontent.com">
                <CategoriesContext.Provider value={categoriesData}>
                    <BrandsContext.Provider value={brandsData}>
                        <ProductTypesContext.Provider value={productTypeData}>
                            <LocationModal.Provider value={[showLocationModal, setShowLocationModal]}>
                                <CustomItemModal.Provider value={[showCustomItemModal, setShowCustomItemModal]}>
                                    <Cart.Provider value={[cart, setCart]}>
                                        <ProfileModals.Provider value={[showProfileModal, setShowProfileModal]}>
                                            <FilterSidebar.Provider
                                                value={[showFilterSidebar, setShowFilterSidebar]}>
                                                <IsDriverApprovedContext.Provider
                                                    value={[isDriverVerified, setIsDriverVerified]}>

                                                    <DriverSidebar.Provider
                                                        value={[showDriverSidebar, setShowDriverSidebar]}>
                                                        <IsCustomerLogin.Provider
                                                            value={[isCustomerLogin, setIsCustomerLogin]}>
                                                            <AllProducts.Provider value={[product, setProduct]}>
                                                                <StickyNavbar.Provider
                                                                    value={[stickyNavbar, setStickyNavbar]}>
                                                                    <BrowserRouter>
                                                                        <Navigation/>
                                                                    </BrowserRouter>
                                                                </StickyNavbar.Provider>
                                                            </AllProducts.Provider>
                                                        </IsCustomerLogin.Provider>
                                                    </DriverSidebar.Provider>
                                                </IsDriverApprovedContext.Provider>
                                            </FilterSidebar.Provider>
                                        </ProfileModals.Provider>
                                    </Cart.Provider>
                                </CustomItemModal.Provider>
                            </LocationModal.Provider>
                        </ProductTypesContext.Provider>
                    </BrandsContext.Provider>
                </CategoriesContext.Provider>


            </GoogleOAuthProvider>


            {/* <Notification/> */}
            {/* <CustomItem/> */}
            {/* <CheckLocation/> */}
            {/* <CartPage /> */}
            {/* <ShippingAndLogisticsPage/> */}
            {/* <CloudStoragePage/> */}
            {/* <ResetPasswordPage/> */}
            {/* <VerificationPage/> */}
            {/* <ForgotPasswordPage/> */}
            {/* <SignupPage/> */}
            {/* <FaqPage/> */}
            {/* <AboutPage/> */}
            {/* <CategoryPage/> */}
            {/* <HomePage /> */}
            {/* <LoginPage /> */}
        </>
    )
}

export default App;
export {
    LocationModal,
    CustomItemModal,
    Cart,
    ProfileModals,
    FilterSidebar,
    DriverSidebar,
    IsCustomerLogin,
    AllProducts,
    StickyNavbar
};

import React, {useContext, useEffect} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import LoginPage from "./pages/authentication-page/login/index"
import SignupPage from "./pages/authentication-page/signup/index"
import ForgotPasswordPage from "./pages/authentication-page/forgot-password/index"
import VerificationPage from "./pages/authentication-page/verification-page/index"
import ResetPasswordPage from "./pages/authentication-page/reset-password/index"
import CategoryPage from "./pages/all-category-page/index";
import AboutPage from "./pages/about-page/index";
import OrderInformationPage from "./pages/faq-page/order-information-page/index";
import ShippingPage from "./pages/faq-page/shipping-page/index";
import ReturnAndCancellationPage from "./pages/faq-page/return-and-cancellation-page/index";

import CloudStoragePage from "./pages/services/cloud-storage";
import ShippingAndLogisticsPage from "./pages/services/shipping-and-logistics/index";
import CartPage from "./pages/cart-page/index";

import CmoreSupplyPage from "./pages/cmore-supply-page/index";
import ProductDescriptionPage from "./pages/product-description-page/index";
import OrderHistoryPage from "./pages/order-history-page/index";
import CloudStorageDashboardPage from "./pages/cloud-storage-dashboard-page/index";

import SearchResultPage from "./pages/search-result-page/index";
import CheckoutPage from "./pages/checkout-page/index"
import ErrorPage from "./pages/error-page/index";

import ProfileSetupPage from "./pages/setup-profile-page/index";
import BankingDocumentationPage from "./pages/banking-documentation/index";
import CarrierDocumentationPage from "./pages/carrier-documentation/index";
import InviteFriendPage from "./pages/invite-friend-page/index";
import TruckAndVehicleTypePage from "./pages/truck-vehicle-type-page/index";
import OrderAndSummaryPage from "./pages/order-summary-trip-page/index";
import AllProductsPage from "./pages/cmore-supply-page/AllProductsPage";
import {CustomItemModal, DriverSidebar, IsCustomerLogin, LocationModal, ProfileModals, StickyNavbar} from "./App";

import CustomerProtectedRoute from "./CustomerProtectedRoute"
import OrderDetailPage from "./pages/order-detail-page";
import HelpCenterPage from "./pages/help-center-page";
import HowItWorksPage from "./pages/how-it-works";
import PaymentMethodPage from "./pages/payment-method-page";
import ViewAllTransactionsPage from "./pages/transaction-history-page";
import DriverProtectedRoute from "./DriverProtectedRoute";
import {GetCurrentUser} from "./services/auth/auth.service";
import {constants} from "./utils/constants";
import DriverVerifiedRoute from "./DriverVerifiedRoute";
import {GetProfile} from "./services/general.service";
import {IsDriverApprovedContext} from "./hooks/GlobalContext";
import HomeNewPage from "./pages/home-page/home-new";
import EditProfileCardNew from "./components/profile/EditProfileCardNew";
import NewTruckAndVehicleTypePage from "./pages/truck-vehicle-type-page/newVehiclePage";
import ProductTablePage from "./pages/product-table-page/ProductTablePage";
import ViewProfileCardNew from "./components/profile/ViewProfileCardNew";
import CurrentOrderNew from "./pages/summary-and-trips-page/current-orders/CurrentOrderNew";
import SingleCurrentOrder from "./pages/summary-and-trips-page/current-orders/SingleCurrentOrder";
import NewOrderPage from "./pages/summary-and-trips-page/new-orders/NewOrderPage";
import NewPastOrder from "./pages/summary-and-trips-page/past-orders/NewPastOrder";
import NewSummaryAndTrip from "./pages/summary-and-trips-page/summary-and-trip/NewSummaryAndTrip";
import SingleNewOrder from "./pages/summary-and-trips-page/new-orders/SingleNewOrder";
import WithDrawlPage from "./pages/withdrawl-page/WithDrawlPage";
import PaymentHistory from "./pages/payment-history/PaymentHistory";
import PageTemplate from "./components/pages/page-template";


const Navigation = () => {
    const location = useLocation();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [showProfileModal, setShowProfileModal] = useContext(ProfileModals);
    const [showLocationModal, setShowLocationModal] = useContext(LocationModal);
    const [showCustomItemModal, setShowCustomItemModal] = useContext(CustomItemModal);
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);
    const [stickyNavbar, setStickyNavbar] = useContext(StickyNavbar);
    const [isDriverVerified, setIsDriverVerified] = useContext(IsDriverApprovedContext);


    useEffect(() => {
        setShowProfileModal(false);
        setShowCustomItemModal(false);
        setShowLocationModal(false)

        document.body.classList.remove("handleProfilemodal");
        document.body.classList.remove("handleFabars");
        if (window.innerWidth < 768) {
            setShowDriverSidebar(false);
        }
        document.body.scrollTop = 0
    }, [location])

    useEffect(() => {
        document.body.addEventListener("scroll", function () {
            if (document.body.scrollTop > 400) {
                setStickyNavbar(true)
            } else {
                setStickyNavbar(false)
            }
        })

        const _user = GetCurrentUser()
        if (_user) {
            console.log(_user, "nav")
            setIsCustomerLogin(_user)
        }

    }, [])

    useEffect(() => {
        getProfile()
    }, [])

    async function getProfile() {
        await GetProfile().then((result) => {
            if (result.status && result.data) {
                let user = result?.data?.user;
                setIsDriverVerified(user.is_driver_verified)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }


    return (
        <Routes>
            <Route path="/" element={<HomeNewPage/>}/>
            <Route path="/all-categories" element={<CategoryPage/>}/>


            <Route path="services">
                <Route path="cloud-storage" element={<CloudStoragePage/>}/>
                <Route path="shipping-and-logistics" element={<ShippingAndLogisticsPage/>}/>
            </Route>

            <Route path="faq">
                <Route path="order-information" element={<OrderInformationPage/>}/>
                <Route path="shipping" element={<ShippingPage/>}/>
                <Route path="return-and-cancellation" element={<ReturnAndCancellationPage/>}/>
            </Route>

            <Route path="product">
                <Route path=":id" element={<ProductDescriptionPage/>}/>
                <Route path="appliances" element={<CmoreSupplyPage heading="Appliances"/>}/>
            </Route>
            <Route path="/privacy-policy" element={<PageTemplate slug="privacy-policy"/>}/>
            <Route path="/terms-and-conditions" element={<PageTemplate slug="terms-and-conditions"/>}/>
            <Route path="checkout" element={<CheckoutPage/>}/>
            <Route path="/all-product" element={<AllProductsPage heading="All Products"/>}/>
            <Route path="/about-us" element={<AboutPage/>}/>
            <Route path="/cart" element={<CartPage/>}/>
            <Route path="/signup"
                   element={(isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_USER) ?
                       <Navigate to="/profile"/>
                       : (isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_DRIVER) ?
                           <Navigate to="/driver/profile-setup"/> : <SignupPage/>}
            />
            <Route path="/login"
                   element={(isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_USER) ?
                       <Navigate to="/profile"/>
                       : (isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_DRIVER) ?
                           <Navigate to="/driver/profile-setup"/> : <LoginPage/>}
            />
            <Route path="/forgot"
                   element={(isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_USER) ?
                       <Navigate to="/profile"/>
                       : (isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_DRIVER) ?
                           <Navigate to="/driver/profile-setup"/> : <ForgotPasswordPage/>}
            />
            <Route path="/verification"
                   element={(isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_USER) ?
                       <Navigate to="/profile"/>
                       : (isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_DRIVER) ?
                           <Navigate to="/driver/profile-setup"/> : <VerificationPage/>}
            />
            <Route path="/reset-password"
                   element={(isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_USER) ?
                       <Navigate to="/profile"/>
                       : (isCustomerLogin && isCustomerLogin.roles[0].id === constants.ROLES.ROLE_DRIVER) ?
                           <Navigate to="/driver/profile-setup"/> : <ResetPasswordPage/>}
            />
            <Route path="/products" element={<CmoreSupplyPage heading="Residentials"/>}/>
            {/*<Route path="/super-duty" element={<CmoreSupplyPage heading="Super Duty" />} />*/}
            {/*<Route path="/tools" element={<CmoreSupplyPage heading="Tools" />} />*/}
            {/*<Route path="/bulk-deals" element={<BulkDealsPage heading="Bulk Deals" />} />*/}
            {/*<Route path="/supplies" element={<CmoreSupplyPage heading="Supplies" />} />*/}
            <Route path="/search-result" element={<SearchResultPage heading="Search results"/>}/>


            <Route path="/cloud-storage" element={<CloudStorageDashboardPage/>}/>
            <Route path="/new-orders" element={<OrderAndSummaryPage/>}/>
            <Route path="/help-center" element={<HelpCenterPage/>}/>
            <Route path="/how-it-works" element={<HowItWorksPage/>}/>
            <Route path="/payment-methods" element={<PaymentMethodPage/>}/>
            <Route path="/view-all-transactions" element={<ViewAllTransactionsPage/>}/>


            {/* protected routes  */}

            <Route element={<CustomerProtectedRoute isLogged={isCustomerLogin}/>}>
                {/*<Route path="/profile" element={<ProfilePage/>}/>*/}
                <Route path="/profile" element={<ViewProfileCardNew/>}/>
                {/*<Route path="/old-edit-profile" element={<EditProfilePage/>}/>*/}
                <Route path="/edit-profile" element={<EditProfileCardNew/>}></Route>
                <Route path="/order-history" element={<OrderHistoryPage/>}/>
                <Route path="/order-history/order-details" element={<OrderDetailPage/>}/>
            </Route>


            <Route element={<DriverProtectedRoute isLogged={isCustomerLogin}/>}>
                <Route path="driver">
                    <Route path="profile-setup" element={<ProfileSetupPage/>}/>
                    <Route path="truck-vehicle-type" element={<TruckAndVehicleTypePage/>}/>

                    <Route element={<DriverVerifiedRoute isLogged={isDriverVerified}/>}>
                        <Route path="banking-documentation" element={<BankingDocumentationPage/>}/>
                        <Route path="carrier-documentation" element={<CarrierDocumentationPage/>}/>
                        <Route path="invite-friend" element={<InviteFriendPage/>}/>
                        {/*<Route path="current-orders" element={<CurrentOrdersPage/>}/>*/}
                        {/*<Route path="new-orders" element={<NewOrdersPage/>}/>*/}

                        <Route path="orders" element={<NewOrderPage/>}/>
                        <Route path="current-orders" element={<NewOrderPage/>}/>
                        <Route path="past-orders" element={<NewOrderPage/>}/>
                        <Route path="completed-orders" element={<NewOrderPage/>}/>
                        <Route path="order-details" element={<SingleCurrentOrder/>}></Route>

                        {/*<Route path="new-orders" element={<NewOrderPage/>}/>*/}
                        {/*<Route path="new-orders-detail" element={<SingleNewOrder/>}/>*/}


                    </Route>

                </Route>
            </Route>

            {/* new changes route  */}
            <Route path="/new-vehicle-type" element={<NewTruckAndVehicleTypePage/>}></Route>
            <Route path="/product-table" element={<ProductTablePage/>}></Route>


            {/* New Page */}

            <Route path="/current-orders-new" element={<CurrentOrderNew/>}></Route>
            <Route path="/current-orders-single" element={<SingleCurrentOrder/>}></Route>
            <Route path="/new-orders-single" element={<SingleNewOrder/>}></Route>
            <Route path="/new-orders-page" element={<NewOrderPage/>}></Route>
            <Route path="/past-orders-page" element={<NewPastOrder/>}></Route>
            <Route path="/summary-and-trip" element={<NewSummaryAndTrip/>}></Route>

            <Route path="/withdrawl" element={<WithDrawlPage/>}></Route>
            <Route path="/payment-history" element={<PaymentHistory/>}></Route>

            {/* New Page */}

            <Route path="*" element={<ErrorPage/>}/>

        </Routes>

    )
}

export default Navigation
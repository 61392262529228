import React, { useContext, useState, useEffect, useRef } from "react";
import { Modal, Button, Row, Col, Form, Container, } from "react-bootstrap";
import DriverProfileModal from "../../../components/profile-modal/DriverProfileModal";
import { IoMdSettings } from "react-icons/io";
import { DriverSidebar } from "../../../App";
import FooterNew from "../../../components/app-footer/FooterNew";
import NewDriverHeader from "../../../components/app-header/NewDriverHeader";
import { DataGrid } from "@mui/x-data-grid";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"

const NewSummaryAndTrip = () => {
    const calendarRef = useRef()
    const [selectedDate, setSelectedDate] = useState({});
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);
    const handleDriverSidebar = () => {
        setShowDriverSidebar(true)
        document.body.classList.add("handleProfilemodal");
    }

    const rows = [
        {
            id: "1",
            order_id: "C1ME-01",
            customer_name: "John Doe",
            phone_number: "345765324",
            order_created: "22/12/21",
            sub_total: "$ 320",
            total: "$ 320",
            status: "C1ME-01"
        },
        {
            id: "2",
            order_id: "C1ME-01",
            customer_name: "John Doe",
            phone_number: "345765324",
            order_created: "22/12/21",
            sub_total: "$ 320",
            total: "$ 320",
            status: "C1ME-01"
        },
        {
            id: "3",
            order_id: "C1ME-01",
            customer_name: "John Doe",
            phone_number: "345765324",
            order_created: "22/12/21",
            sub_total: "$ 320",
            total: "$ 320",
            status: "C1ME-01"
        },
        {
            id: "4",
            order_id: "C1ME-01",
            customer_name: "John Doe",
            phone_number: "345765324",
            order_created: "22/12/21",
            sub_total: "$ 320",
            total: "$ 320",
            status: "C1ME-01"
        },
        {
            id: "5",
            order_id: "C1ME-01",
            customer_name: "John Doe",
            phone_number: "345765324",
            order_created: "22/12/21",
            sub_total: "$ 320",
            total: "$ 320",
            status: "C1ME-01"
        },
    ]

    const columns = [
        {
            field: "order_id",
            headerName: "Order ID",
            minWidth: 150,
            flex: 1,
            align: "left",
            headerAlign: "left",
            filterable: false,
        },
        {
            field: "customer_name",
            headerName: "Customer Name",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        },
        {
            field: "phone_number",
            headerName: "Phone Number",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        },
        {
            field: "order_created",
            headerName: "Order Created",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        },
        {
            field: "sub_total",
            headerName: "Sub Total",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        },
        {
            field: "total",
            headerName: "Total",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <div className="d-flex align-items-center me-3">
                            <div className="circle-yellow me-3"></div>
                            <span>{params.value}</span>
                        </div>
                    </div>
                );
            }
        },
    ];


    return (
        <>
            <NewDriverHeader />
            <div className="summary-trips">
                <div className="d-flex">
                    <div className="left">
                        {
                            showDriverSidebar === true &&
                            <DriverProfileModal />
                        }
                    </div>

                    <div className="right py-5">
                        <Container>
                            <Row>
                                <Col className="d-block d-md-none" xs={12}>
                                    <div className="text-end">
                                        <IoMdSettings onClick={() => handleDriverSidebar(true)} className="text-white icon-setting" />
                                        {
                                            showDriverSidebar == false &&
                                            <hr />
                                        }
                                    </div>
                                </Col>

                                <Col xs={12} md={12} lg={6} className="">
                                    <h1>Summary & Trips</h1>
                                </Col>

                                <Col xs={12} md={12} lg={6} className="my-auto">
                                    <div className="custom mt-2 mt-lg-0 d-flex justify-content-lg-end">
                                        <div className="d-flex align-items-center me-3">
                                            <div className="circle-red me-3"></div>
                                            <span>Not started</span>
                                        </div>

                                        <div className="d-flex align-items-center me-3">
                                            <div className="circle-yellow me-3"></div>
                                            <span>Return Processing</span>
                                        </div>

                                        <div className="d-flex align-items-center me-1">
                                            <div className="circle-green me-3"></div>
                                            <span>Order Returned</span>
                                        </div>
                                    </div>
                                </Col>

                                {/* <Col xs={12} md={6} className="mt-4">
                                    <div className="sort-container">
                                        <Button className="active">Month</Button>
                                        <Button>Week</Button>
                                        <Button>Day</Button>
                                    </div>
                                </Col>

                                <Col xs={12} md={6} className="mt-4">
                                    <div className="d-flex align-items-center justify-content-start justify-content-md-end text-white">
                                        <div className="chev-icon-div">
                                            <FiChevronLeft className="text-grey icon" />
                                        </div>

                                        <div className="mx-3">
                                            <h5 className="m-0">January 2022</h5>
                                        </div>

                                        <div className="chev-icon-div">
                                            <FiChevronRight className="text-grey icon" />
                                        </div>
                                    </div>
                                </Col> */}

                                <div className="newCalendar mt-3">
                                    <FullCalendar
                                        ref={calendarRef}
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        headerToolbar={{
                                            left: "prev,next",
                                            center: "title",
                                            right: "dayGridMonth,timeGridWeek,timeGridDay"
                                        }}
                                        initialView="timeGridWeek"
                                        displayEventTime={true}
                                        datesSet={(date) => setSelectedDate({ start: date.startStr.split("T")[0], end: date.endStr.split("T")[0] })}
                                    />
                                </div>

                                <Col xs={12}>
                                    <div className="ProductProductTable NewOrderTable mt-4" style={{ height: 530, width: "100%" }}>
                                        <DataGrid
                                            disableColumnMenu
                                            disableColumnFilter
                                            disableColumnSelector
                                            className="text-white data-table"
                                            rows={rows}
                                            columns={columns}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <FooterNew />
        </>
    )
}

export default NewSummaryAndTrip

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/cmore-styles.css";
import "./assets/css/custom.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";

import {Provider} from "react-redux";
import store from "./store";

const rootElement = document.getElementById("root");

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    rootElement
);

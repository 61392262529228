import { BASE_URL, ENDPOINTS } from "../../constants/app-constants";
import { curl_post, requestSuccessAction } from "./http";
import { gotoHome } from "./router";

const types = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_FAILED: "USER_LOGIN_FAILED",

  REFRESH_TOKEN: "REFRESH_TOKEN",
  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_FAILED: "REFRESH_TOKEN_FAILED",
};

export default types;

export function login(payload) {
  return (dispatch) => {
    dispatch({ type: types.USER_LOGIN_REQUEST });
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.LOGIN,
      payload,
      types.USER_LOGIN_FAILED,
      (data) => {
        // data.email = payload.get("email");
        dispatch(requestSuccessAction(data, types.USER_LOGIN));
        // Set Success Message then Redirect To Home
        dispatch(gotoHome());
      }
    );
  };
}
